import React from 'react';
import ReactDOM from 'react-dom';
// import { HelmetProvider } from 'react-helmet-async';
import 'antd/dist/antd.css';
// import App from './App';
// import MetaTags from './Components/MetaTags/MetaTags';
import './index.scss';

function Redirect() {
  // eslint-disable-next-line no-restricted-globals
  location.href = 'https://alejandrolea.fr';
  return <div />;
}

ReactDOM.render(
  <React.StrictMode>
    <Redirect />
    {/* <HelmetProvider>
      <Suspense fallback={null}>
        <MetaTags />
        <App />
      </Suspense>
    </HelmetProvider> */}
  </React.StrictMode>,
  document.getElementById('root'),
);
